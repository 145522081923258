import { useState } from "react";
import CalendarChildren from "./CalendarChildren";
import {
  format,
  subMonths,
  addMonths,
  addDays,
  isFirstDayOfMonth,
  getMonth,
  getWeek,
  addWeeks,
  subWeeks,
} from "date-fns";
import { fr } from "date-fns/locale";
import FetchReservation from "./FetchReservation";
import Tri from "./Tri";
import isSaturday from "date-fns/isSaturday";
import isFriday from "date-fns/isFriday";
import saisons from "./saisons.json";

const Calendar = ({
  roomSelectedType,
  dateSelectioned,
  setmodalChambre,
  setmodalVilla,
  setmodalSuite,
  setmodalBois,
}) => {

  const [activeCalendar, setActiveCalendar] = useState("");
  const [data, setData] = useState([]);
  const [resaSelected, setResaSelected] = useState([]);
  const [roomSelected, setRoomSelected] = useState([]);
  const reservations = FetchReservation();

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());

  function arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele.SUMMARY != value;
    });
  }

  /****** Find out if element appear n° of time ( second paramater) ************/

  const getSameDayBooked = (arr, num) => {
    const count = (arr, value) =>
      arr.filter((val) => val.getTime() === value.getTime()).length;
    const similarDays = [];

    const howMany = arr.flat().map((b) => count(arr.flat(), b));

    howMany.map((v, i) => {
      if (v === num) {
        similarDays.push(arr.flat().splice(i, 1));
      }
    });
    return similarDays;
  };

  /******************************************************************************** */

  /*******Tri les réservation par type établissement */
  const villaAllDays = Tri(
    reservations.filter((book) => book.SUMMARY.includes("Villa"))
  );
  /****Second paramètre nombre d'établissement resérvé le même jours afin de rendre TRUE */
  const villaAllDayBookSameDay = getSameDayBooked(villaAllDays, 6);

  const suiteAllDays = arrayRemove(
    reservations.filter((book) => book.SUMMARY.includes("Suite")),
    "Suite Bois noir"
  );
  const suiteAllDaysBookSameDay = getSameDayBooked(Tri(suiteAllDays), 6);

  let boisNoir = reservations.filter((book) => book.SUMMARY.includes("Bois"));
  const boisNoirAllDays = Tri(boisNoir);

  const ChambreAllDays = reservations.filter((book) =>
    book.SUMMARY.includes("Chambre")
  );

  const ChambreAllDaysBookSameDay = getSameDayBooked(Tri(ChambreAllDays), 6);

  const fermetureDays = Tri(
    reservations.filter((book) => book.SUMMARY.includes("indisponible"))
  );

  if (fermetureDays) {
    fermetureDays.forEach((daysClosed) => {
      daysClosed.forEach((dayClosed) => {
        ChambreAllDaysBookSameDay.push(dayClosed);
        villaAllDayBookSameDay.push(dayClosed);
        boisNoirAllDays.push(dayClosed);
        suiteAllDaysBookSameDay.push(dayClosed);
      });
    });
  }

  /******************************************************************************** */

  const showDetailsHandle = (dayStr, room) => {
    if (resaSelected.length <= 2) {
      setResaSelected((array) => [...array, dayStr]);
      setRoomSelected((array) => [...array, room]);
    } else {
      setResaSelected([]);
      setRoomSelected([]);
    }
    dateSelectioned((array) => [...array, dayStr]);
    roomSelectedType((array) => [...array, room]);

    //   const alreadySelected  = data.some(function(arr) {
    //     return arr.every(function(prop, index) {
    //       return [room,dayStr][index] === prop
    //     })
    //   });

    // if(!alreadySelected){
    //    setData((array => [...array,[room, dayStr]]))

    // }else{

    //   setData(array => [...array.filter((e) => (e[1] != dayStr || e[0] != room  ))]);
    //   // console.log(array => [...array.filter((e) => (console.log(e[1])))]);
    //   // setData(array => [...array.filter((e) => (e[1] != dayStr || e[0] != room  ))]);

    // }    setShowDetails(true);
  };

  const changeMonthHandle = (btnType) => {
    let today = new Date();

    if (btnType === "prev") {
      if (today < currentMonth) {
        setCurrentMonth(subMonths(currentMonth, 1));

        if (today > subMonths(currentMonth, 1)) {
          setCurrentMonth(today);
          setCurrentWeek(getWeek(today));
        }
      }
    }
    if (btnType === "next") {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };

  const changeWeekHandle = (btnType) => {
    let today = new Date();
    if (btnType === "prev") {
      if (today <= currentMonth) setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));

      if (today > currentMonth) {
        setCurrentMonth(today);
        setCurrentWeek(getWeek(today));
      }
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  const RenderHeader = () => {
    const nextMonth = addMonths(currentMonth, 1);
    const isCurrentMonthEven = getMonth(currentMonth) % 2 === 0;
    const isNextMonthEven = getMonth(nextMonth) % 2 === 0;
    const monthDisplayClass = isCurrentMonthEven
      ? "monthDisplay evenMonthText"
      : "monthDisplay oddMonthText";
    const nextMonthDisplayClass = isNextMonthEven
      ? "monthDisplay evenMonthText"
      : "monthDisplay oddMonthText";

    const dateFormat = "MMM yyyy";

    // console.log("selected day", selectedDate);
    return (
      <div className="header row flex-middle">
        <div className={`col ${monthDisplayClass}`}>
          <span>{format(currentMonth, dateFormat, { locale: fr })}</span>
        </div>
        <div className="col col-start">
          <div className="icon" onClick={() => changeWeekHandle("prev")}>
            &lt; Semaine
          </div>
        </div>
        <div className="col col-start">
          <div className="icon" onClick={() => changeMonthHandle("prev")}>
            &lt; Mois
          </div>
        </div>

        <div className="col col-end">
          <div className="icon" onClick={() => changeMonthHandle("next")}>
            Mois &gt;
          </div>
        </div>
        <div className="col col-end" onClick={() => changeWeekHandle("next")}>
          <div className="icon"> Semaine &gt;</div>
        </div>
        <div className={`col ${nextMonthDisplayClass}`}>
          <span>{format(nextMonth, dateFormat, { locale: fr })}</span>
        </div>
      </div>
    );
  };
  const RenderHeaderMobile = () => {
    const dateFormat = "MMM yyyy";

    const nextMonth = addMonths(currentMonth, 1);

    // console.log("selected day", selectedDate);
    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={() => changeWeekHandle("prev")}>
            &lt; Semaine
          </div>
        </div>
        <div className="col col-start">
          <div className="icon" onClick={() => changeMonthHandle("prev")}>
            &lt; Mois
          </div>
        </div>
        <div className="col  monthMobile monthDisplay">
          <span>{format(currentMonth, dateFormat)}</span>
        </div>

        <div className="col col-end">
          <div className="icon" onClick={() => changeMonthHandle("next")}>
            Mois &gt;
          </div>
        </div>
        <div className="col col-end" onClick={() => changeWeekHandle("next")}>
          <div className="icon"> Semaine &gt;</div>
        </div>
      </div>
    );
  };

  const RenderSaisons = (howManyDaYs) => {
    let startDate = currentMonth;
    const days = [];
    const lowSaisons = [];

    const startFoireDeBourail = Date.parse(saisons.foireDeBourail[0].start);
    const endFoireDeBourail = Date.parse(saisons.foireDeBourail[0].end);

    const startHolidays = Date.parse(saisons.vacanceScolaire[0].start);
    const endHolidays = Date.parse(saisons.vacanceScolaire[0].end);
    const startHolidays1 = Date.parse(saisons.vacanceScolaire[1].start);
    const endHolidays1 = Date.parse(saisons.vacanceScolaire[1].end);
    const startHolidays2 = Date.parse(saisons.vacanceScolaire[2].start);
    const endHolidays2 = Date.parse(saisons.vacanceScolaire[2].end);
    const startHolidays3 = Date.parse(saisons.vacanceScolaire[3].start);
    const endHolidays3 = Date.parse(saisons.vacanceScolaire[3].end);
    const startHolidays4 = Date.parse(saisons.vacanceScolaire[4].start);
    const endHolidays4 = Date.parse(saisons.vacanceScolaire[4].end);

    for (let i = 0; i < howManyDaYs.howManyDays; i++) {
      if (
        new Date(addDays(startDate, i)).getTime() >= startFoireDeBourail &&
        new Date(addDays(startDate, i)).getTime() <= endFoireDeBourail
      ) {
        lowSaisons.push(<div className="foireDeBourail"></div>);
      } else if (
        (new Date(addDays(startDate, i)).getTime() >= startHolidays &&
          new Date(addDays(startDate, i)).getTime() <= endHolidays) ||
        (new Date(addDays(startDate, i)).getTime() >= startHolidays1 &&
          new Date(addDays(startDate, i)).getTime() <= endHolidays1) ||
        (new Date(addDays(startDate, i)).getTime() >= startHolidays2 &&
          new Date(addDays(startDate, i)).getTime() <= endHolidays2) ||
        (new Date(addDays(startDate, i)).getTime() >= startHolidays3 &&
          new Date(addDays(startDate, i)).getTime() <= endHolidays3) ||
        (new Date(addDays(startDate, i)).getTime() >= startHolidays4 &&
          new Date(addDays(startDate, i)).getTime() <= endHolidays4)
      ) {
        lowSaisons.push(<div className="holidaysSaison"></div>);
      } else {
        lowSaisons.push(<div className="lowSaison"></div>);
      }
    }

    return lowSaisons;
  };

  const RenderDays = () => {
    const dateFormat = "MM/dd/yyyy";
    const days = [];

    let startDate = currentMonth;

    const options = { weekday: "short", day: "numeric" };

    for (let i = 0; i < 32; i++) {
      const currentDate = addDays(startDate, i);
      const isCurrentMonthEven = getMonth(currentDate) % 2 === 0;

      days.push(
        <div
          className={`col col-center ${
            isSaturday(currentDate) || isFriday(currentDate) ? "weekend" : ""
          } ${isFirstDayOfMonth(currentDate) ? "first" : ""} ${
            isCurrentMonthEven ? "evenMonth" : "oddMonth"
          }`}
          key={i}
        >
          <p>
            {new Date(format(addDays(startDate, i), dateFormat))
              .toLocaleDateString("fr-Fr", { weekday: "short" })
              .slice(-4, 2)}
          </p>
          <p>
            {new Date(currentDate).toLocaleDateString("fr-Fr", {
              day: "numeric",
            })}
          </p>
        </div>
      );
    }

    return (
      <>
        <div className="saisonContainer">
          <p
            style={{ fontSize: ".8em", marginLeft: "5rem", fontWeight: "bold" }}
          >
            Saisons
          </p>
          <div className="saisons">
            <RenderSaisons howManyDays={32} />
          </div>
        </div>
        <div className="daysRowContainer">
          <div className="days row">
            {" "}
            <div
              className="col col-center people1"
              title="Personnes max sans lits supplémentaires"
            >
              <i className="fa-solid fa-person"></i>
            </div>{" "}
            <div
              className="col col-center people"
              title="Personnes supplémentaires autorisés avec lits d'appoint"
            >
              <i className="fa-solid fa-person">
                <p>Max</p>
              </i>
            </div>
            {days}
          </div>
        </div>
      </>
    );
  };

  const RenderDaysMobile = () => {
    // const dateFormat = "EEE";
    const dateFormat = "MM/dd/yyyy";
    const days = [];
    let startDate = currentMonth;
    // let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const options = { weekday: "short" };
    const options1 = { day: "numeric" };
    for (let i = 0; i < 10; i++) {
      days.push(
        <div
          className={`col col-center ${
            isSaturday(new Date(addDays(startDate, i))) ||
            isFriday(new Date(addDays(startDate, i)))
              ? "weekend"
              : ""
          }`}
          key={i}
        >
          {/* {format(addDays(startDate, i), dateFormat)} */}
          <p>
            {" "}
            {new Date(
              format(addDays(startDate, i), dateFormat)
            ).toLocaleDateString("fr-Fr", options)}
          </p>
          <p>
            {" "}
            {new Date(
              format(addDays(startDate, i), dateFormat)
            ).toLocaleDateString("fr-Fr", options1)}
          </p>
        </div>
      );
    }

    return (
      <div className="daysRowContainer">
        <div className="saisonContainer">
          <div className="saisons">
            <RenderSaisons howManyDays={10} />
          </div>
        </div>
        <div className="days row">{days}</div>
      </div>
    );
  };
  const RenderDaysTablet = () => {
    // const dateFormat = "EEE";
    const dateFormat = "MM/dd/yyyy";
    const days = [];
    // let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    let startDate = currentMonth;
    const options = { weekday: "short" };
    const options1 = { day: "numeric" };
    for (let i = 0; i < 14; i++) {
      days.push(
        <div
          className={`col col-center ${
            isSaturday(new Date(addDays(startDate, i))) ||
            isFriday(new Date(addDays(startDate, i)))
              ? "weekend"
              : ""
          }`}
          key={i}
        >
          {/* {format(addDays(startDate, i), dateFormat)} */}
          <p>
            {" "}
            {new Date(
              format(addDays(startDate, i), dateFormat)
            ).toLocaleDateString("fr-Fr", options)}
          </p>
          <p>
            {" "}
            {new Date(
              format(addDays(startDate, i), dateFormat)
            ).toLocaleDateString("fr-Fr", options1)}
          </p>
        </div>
      );
    }

    return (
      <div className="daysRowContainer">
        <div className="saisonContainer">
          <div className="saisons">
            <RenderSaisons howManyDays={14} />
          </div>
        </div>
        <div className="days row">{days}</div>
      </div>
    );
  };

  const renderCalendars = () => {
    return (
      <>
        <div onClick={() => setActiveCalendar("1")}>
          <CalendarChildren
            key={"1"}
            showDetailsHandle={showDetailsHandle}
            currentWeek={currentWeek}
            currentMonth={currentMonth}
            uniteName={"Villas"}
            active={activeCalendar}
            id={"25"}
            index={"1"}
            resetData={setData}
            bookedDays={villaAllDayBookSameDay}
            background={"#f8cbad"}
            people={6}
            peopleMax={9}
            setmodal={setmodalVilla}
          />
        </div>
        <div onClick={() => setActiveCalendar("2")}>
          <CalendarChildren
            key={"2"}
            showDetailsHandle={showDetailsHandle}
            currentWeek={currentWeek}
            currentMonth={currentMonth}
            uniteName={"Suites"}
            active={activeCalendar}
            id={"25"}
            index={"2"}
            resetData={setData}
            bookedDays={suiteAllDaysBookSameDay}
            background={"#8faadc"}
            people={4}
            peopleMax={6}
            setmodal={setmodalSuite}
          />
        </div>
        <div onClick={() => setActiveCalendar("3")}>
          <CalendarChildren
            key={"4"}
            showDetailsHandle={showDetailsHandle}
            currentWeek={currentWeek}
            currentMonth={currentMonth}
            uniteName={"Bois Noir"}
            active={activeCalendar}
            id={"25"}
            index={"3"}
            resetData={setData}
            bookedDays={boisNoirAllDays}
            background={"#b4c7e7"}
            people={2}
            peopleMax={4}
            setmodal={setmodalBois}
          />
        </div>
        <div onClick={() => setActiveCalendar("4")}>
          <CalendarChildren
            key={"3"}
            showDetailsHandle={showDetailsHandle}
            currentWeek={currentWeek}
            currentMonth={currentMonth}
            uniteName={"Chambres"}
            active={activeCalendar}
            id={"25"}
            index={"4"}
            resetData={setData}
            bookedDays={ChambreAllDaysBookSameDay}
            background={"#deebf7"}
            people={2}
            peopleMax={3}
            setmodal={setmodalChambre}
          />
        </div>
      </>
    );
  };

  return (
    <div className="calendar">
      <div className="mobileRenderDays">
        <RenderHeaderMobile />
      </div>
      <div className="mobileRenderDays">
        <RenderDaysMobile />
      </div>
      <div className="desktopRenderDays">
        <RenderHeader />
      </div>
      <div className="desktopRenderDays">
        <RenderDays />
      </div>
      <div className="tabletRenderDays">
        <RenderHeaderMobile />
      </div>

      <div className="tabletRenderDays">
        <RenderDaysTablet />
      </div>
      {renderCalendars()}

      {/* {renderCells()} */}
    </div>
  );
};

export default Calendar;
